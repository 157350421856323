import {
  memo, useCallback, useState, useMemo,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  NumericTextField,
  DialogActions,
  Button,
  useTranslations,
  Typography,
  S5,
  ReactHookFormCheckbox,
  useSnackbar,
  RenderIf,
  Spinner,
  B1,
  minLengthValidator,
  maxLengthValidator,
  getUseTimerExpiryTimestamp,
  ReactHookFormSelect,
  MenuItem,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import { useTimer } from 'react-timer-hook';
import { logException } from '../../../../../../services/sentryService';
import { UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP } from '../../../../../../constants';
import usePartnerOrganizationsAPI from '../../../../../../hooks/usePartnerOrganizationsAPI';
import TimeSpanEnum from '../../../../../../enums/TimeSpanEnum';

export interface IDeleteOrganizationModalSubmitResult {
  code: string;
  retentionPeriodInDays: number;
}

interface IDeleteOrganizationModalFormValue {
  code: string;
  agreement: boolean;
  retentionPeriodInDays: string | number;
}

interface IDeleteOrganizationModalProps {
  organizationName: string;
  organizationId: string;
  isOpen: boolean;
  isDeleteOrganizationLoading: boolean;
  onSubmit: (params: IDeleteOrganizationModalSubmitResult) => Promise<void> | void;
  onClose: () => void;
}

const TIME_SPAN_TRANSLATION_KEYS = {
  [TimeSpanEnum.Thirty]: 'common.time.span.days.thirty',
  [TimeSpanEnum.Sixty]: 'common.time.span.days.sixty',
  [TimeSpanEnum.Ninety]: 'common.time.span.days.ninety',
};

const RETENTION_PERIOD_OPTIONS = [
  {
    label: TIME_SPAN_TRANSLATION_KEYS[TimeSpanEnum.Thirty],
    value: TimeSpanEnum.Thirty,
  },
  {
    label: TIME_SPAN_TRANSLATION_KEYS[TimeSpanEnum.Sixty],
    value: TimeSpanEnum.Sixty,
  },
  {
    label: TIME_SPAN_TRANSLATION_KEYS[TimeSpanEnum.Ninety],
    value: TimeSpanEnum.Ninety,
  },
];

const DESCRIPTION_TRANSLATIONS_CONFIG = [
  {
    key: 'firstItem',
    first: 'organization.settings.delete.form.description.items.first.firstPart',
    second: 'organization.settings.delete.form.description.items.first.secondPart',
    showOrganizationName: true,
  },
  {
    key: 'secondItem',
    first: 'organization.settings.delete.form.description.items.second.firstPart',
    second: 'organization.settings.delete.form.description.items.second.secondPart',
    showOrganizationName: true,
  },
  {
    key: 'thirdItem',
    first: null,
    second: 'organization.settings.delete.form.description.items.third.secondPart',
    showOrganizationName: true,
  },
  {
    key: 'fourthItem',
    first: null,
    second: 'organization.settings.delete.form.description.items.fourth.secondPart',
    showOrganizationName: true,
  },
  {
    key: 'fifthItem',
    first: null,
    second: 'organization.settings.delete.form.description.items.fifth.secondPart',
    showOrganizationName: false,
  },
];

const DeleteOrganizationModal = (props: IDeleteOrganizationModalProps) => {
  const {
    organizationName, organizationId, isOpen, isDeleteOrganizationLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const {
    totalSeconds,
    restart,
  } = useTimer({
    expiryTimestamp: getUseTimerExpiryTimestamp(60),
  });

  const { requestOrganizationDeletion } = usePartnerOrganizationsAPI();

  const [isResendCodeLoading, setIsResendCodeLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    register,
  } = useForm<IDeleteOrganizationModalFormValue>({
    defaultValues: {
      code: '',
      agreement: false,
      retentionPeriodInDays: '',
    },
    mode: 'all',
  });

  const resendCode = useCallback(async () => {
    try {
      setIsResendCodeLoading(true);
      await requestOrganizationDeletion({ organizationId });
      restart(getUseTimerExpiryTimestamp(60));
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'DeleteOrganizationModal/resendCode exception' });
    } finally {
      setIsResendCodeLoading(false);
    }
  }, [organizationId, requestOrganizationDeletion, restart, showError, t]);

  const handleFormSubmit = useCallback((formValue: IDeleteOrganizationModalFormValue) => {
    const { code, retentionPeriodInDays } = formValue;
    onSubmit({ code, retentionPeriodInDays: retentionPeriodInDays as number });
  }, [onSubmit]);

  const descriptionElements = useMemo(
    () => DESCRIPTION_TRANSLATIONS_CONFIG.map((description) => (
      <Grid container flexWrap="nowrap" key={description.key}>
        <Grid item mx={1}>
          <Typography>
            •
          </Typography>
        </Grid>
        <Grid item>
          <RenderIf condition={!!description.first}>
            <Typography component="span">
              {t(description.first!)}
              &nbsp;
            </Typography>
          </RenderIf>
          <RenderIf condition={description.showOrganizationName}>
            <Typography component="span" color={B1}>
              {organizationName}
              &nbsp;
            </Typography>
          </RenderIf>
          <Typography component="span">
            {t(description.second)}
          </Typography>
        </Grid>
      </Grid>
    )),
    [organizationName, t],
  );

  const [
    enteredCode,
    selectedAgreement,
    retentionPeriodInDays,
  ] = watch([
    'code',
    'agreement',
    'retentionPeriodInDays',
  ]);

  const isResetCodeDisabled = !!totalSeconds || isResendCodeLoading || isDeleteOrganizationLoading;

  const isDeleteOrganizationDisabled = !enteredCode
    || !!errors.code
    || !selectedAgreement
    || !retentionPeriodInDays
    || isDeleteOrganizationLoading;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isDeleteOrganizationLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isDeleteOrganizationLoading} onClose={onClose}>
          {t('organization.settings.delete.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item xs={12} flexDirection="column" flexWrap="nowrap">
              <Typography variant="subtitle2">
                {t('organization.settings.delete.form.description.title.items.first')}
                &nbsp;
                <Typography component="span" variant="subtitle2" color={B1}>
                  {organizationName}
                </Typography>
                &nbsp;
                <Typography component="span" variant="subtitle2">
                  {t('organization.settings.delete.form.description.title.items.second')}
                </Typography>
              </Typography>
              <Typography variant="caption">
                {t('organization.settings.delete.form.description.subtitle')}
              </Typography>
              {descriptionElements}
            </Grid>
            <Grid item container xs={12} my={1} columnGap={1} alignItems="center" flexWrap="nowrap">
              <ReactHookFormCheckbox
                name="agreement"
                label={`*${t('organization.settings.agreement')}`}
                control={control}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericTextField
                allowLeadingZeros
                fullWidth
                autoComplete="none"
                value={enteredCode}
                label={`*${t('organization.settings.delete.form.code.label')}`}
                error={!!errors.code}
                helperText={errors.code?.message}
                inputProps={{
                  ...register('code', {
                    validate: {
                      minLength: (
                        code,
                      ) => minLengthValidator(6, code as string) || t('validation.invalidFormat'),
                      maxLength: (
                        code,
                      ) => maxLengthValidator(6, code as string) || t('validation.invalidFormat'),
                    },
                  }),
                  ...UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormSelect
                name="retentionPeriodInDays"
                fullWidth
                label={`*${t('organization.settings.delete.form.retentionPeriodInDays.label')}`}
                control={control}
              >
                {
                  RETENTION_PERIOD_OPTIONS.map((period) => (
                    <MenuItem key={period.value} value={period.value}>
                      {t(period.label)}
                    </MenuItem>
                  ))
                }
              </ReactHookFormSelect>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" fontWeight={400} color={S5}>
                {t('organization.settings.delete.form.codeAlreadySent.title')}
                &nbsp;
              </Typography>
              <Typography
                component="span"
                variant="body2"
                fontWeight={400}
                asLink
                disabled={isResetCodeDisabled}
                onClick={isResetCodeDisabled ? undefined : resendCode}
              >
                {totalSeconds
                  ? t('organization.settings.delete.form.youCanResendCodeIn.title')
                  : t('organization.settings.delete.form.resend.button.title')}
              </Typography>
              &nbsp;
              <RenderIf condition={isResendCodeLoading}>
                <Spinner size={12} />
              </RenderIf>
              <RenderIf condition={!!totalSeconds}>
                <Typography component="span" variant="body2" fontWeight={400}>
                  {totalSeconds}
                </Typography>
              </RenderIf>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                disabled={isDeleteOrganizationLoading}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="error"
                isLoading={isDeleteOrganizationLoading}
                disabled={isDeleteOrganizationDisabled}
                type="submit"
              >
                {t('common.delete')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(DeleteOrganizationModal);
