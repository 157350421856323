import {
  memo, useCallback, useMemo,
} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  useTranslations,
  Typography,
  ReactHookFormCheckbox,
  RenderIf,
  B1,
  ReactHookFormAutocomplete,
  useSnackbar,
} from '@uniqkey-frontend/shared-app';
import { useForm } from 'react-hook-form';
import {
  RestoreOrganizationRequest,
  GetEmployeeAccountsResponseModel,
  EmployeeAccountType,
} from '@uniqkey-backend-partner/api-client';
import useEmployeeAccountsAPI from '../../../../../../hooks/useEmployeeAccountsAPI';

export interface IRestoreOrganizationModalSubmitResult extends RestoreOrganizationRequest{}

interface IRestoreOrganizationModalFormValue {
  employeeAccounts: IEmployeeAccountOption[];
  agreement: boolean;
}

interface IEmployeeAccountOption {
  value: string;
  label: string;
}

interface IRestoreOrganizationModalProps {
  organizationId: string;
  organizationName: string;
  isOpen: boolean;
  isRestoreOrganizationLoading: boolean;
  onSubmit: (params: IRestoreOrganizationModalSubmitResult) => Promise<void> | void;
  onClose: () => void;
}

const DESCRIPTION_TRANSLATIONS_CONFIG = [
  {
    key: 'firstItem',
    first: 'organization.settings.restore.form.description.items.first.firstPart',
    second: null,
    showOrganizationName: false,
  },
  {
    key: 'secondItem',
    first: 'organization.settings.restore.form.description.items.second.firstPart',
    second: null,
    showOrganizationName: true,
  },
  {
    key: 'thirdItem',
    first: 'organization.settings.restore.form.description.items.third.firstPart',
    second: 'organization.settings.restore.form.description.items.third.secondPart',
    showOrganizationName: true,
  },
  {
    key: 'fourthItem',
    first: 'organization.settings.restore.form.description.items.fourth.firstPart',
    second: null,
    showOrganizationName: false,
  },
];

const RestoreOrganizationModal = (props: IRestoreOrganizationModalProps) => {
  const {
    organizationId, organizationName, isOpen, isRestoreOrganizationLoading, onSubmit, onClose,
  } = props;

  const { t } = useTranslations();
  const { showError } = useSnackbar();

  const {
    handleSubmit,
    control,
    watch,
  } = useForm<IRestoreOrganizationModalFormValue>({
    defaultValues: {
      employeeAccounts: [],
      agreement: false,
    },
  });

  const handleFormSubmit = useCallback((formValue: IRestoreOrganizationModalFormValue) => {
    const { employeeAccounts } = formValue;
    const employeeAccountIds = employeeAccounts.map(
      (employeeAccount) => employeeAccount.value,
    );
    onSubmit({ employeeAccountIds });
  }, [onSubmit]);

  const { getOrganizationEmployeeAccounts } = useEmployeeAccountsAPI();

  const handleGetOrganizationsForLookupRequest = useCallback(async (searchQuery: string) => {
    try {
      const { data } = await getOrganizationEmployeeAccounts({
        page: 1,
        pageLength: 100,
        organizationId,
        searchQuery,
        typeFilter: EmployeeAccountType.Admin,
      });
      return data;
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      return [];
    }
  }, [getOrganizationEmployeeAccounts, organizationId, showError, t]);

  const handleGetOrganizationsForLookupResponseParser = useCallback((
    employeeAccounts: GetEmployeeAccountsResponseModel[],
  ) => employeeAccounts.map(
    (employeeAccount) => ({
      value: employeeAccount.employeeAccountId,
      label: employeeAccount.name
        ? `${employeeAccount.name}, ${employeeAccount.email}`
        : `${employeeAccount.email}`,
    }),
  ), []);

  const descriptionElements = useMemo(
    () => DESCRIPTION_TRANSLATIONS_CONFIG.map((description) => (
      <Grid container flexWrap="nowrap" key={description.key}>
        <Grid item mx={1}>
          <Typography>
            •
          </Typography>
        </Grid>
        <Grid item>
          <RenderIf condition={!!description.first}>
            <Typography component="span">
              {t(description.first!)}
              &nbsp;
            </Typography>
          </RenderIf>
          <RenderIf condition={description.showOrganizationName}>
            <Typography component="span" color={B1}>
              {organizationName}
              &nbsp;
            </Typography>
          </RenderIf>
          <RenderIf condition={!!description.second}>
            <Typography component="span">
              {t(description.second!)}
            </Typography>
          </RenderIf>
        </Grid>
      </Grid>
    )),
    [organizationName, t],
  );

  const [
    selectedEmployeeAccounts,
    selectedAgreement,
  ] = watch([
    'employeeAccounts',
    'agreement',
  ]);

  const isRestoreOrganizationDisabled = !selectedEmployeeAccounts.length
    || !selectedAgreement
    || isRestoreOrganizationLoading;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      clickOutsideToClose={!isRestoreOrganizationLoading}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off" noValidate>
        <DialogTitle isLoading={isRestoreOrganizationLoading} onClose={onClose}>
          {t('organization.settings.restore.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item xs={12} flexDirection="column" flexWrap="nowrap">
              <Typography variant="subtitle2">
                {t('organization.settings.restore.form.description.title.items.first')}
                &nbsp;
                <Typography component="span" variant="subtitle2" color={B1}>
                  {organizationName}
                </Typography>
                &nbsp;
                <Typography component="span" variant="subtitle2">
                  {t('organization.settings.restore.form.description.title.items.second')}
                </Typography>
              </Typography>
              <Typography variant="caption">
                {t('organization.settings.restore.form.description.subTitle')}
              </Typography>
              {descriptionElements}
            </Grid>
            <Grid item container xs={12} my={1} columnGap={1} alignItems="center" flexWrap="nowrap">
              <ReactHookFormCheckbox
                name="agreement"
                label={`*${t('organization.settings.agreement')}`}
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <ReactHookFormAutocomplete
                name="employeeAccounts"
                control={control}
                useCustomPopperPreventOverflowModifier
                t={t}
                multiple
                label={`*${t('organization.settings.restore.form.employeeAccount.label')}`}
                placeholder={t('organization.settings.restore.form.employeeAccount.placeholder')}
                dataSourceRequest={handleGetOrganizationsForLookupRequest}
                dataSourceResponseParser={handleGetOrganizationsForLookupResponseParser}
                fetchOnEmptyQuery
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="outlined"
                disabled={isRestoreOrganizationLoading}
                onClick={onClose}
              >
                {t('common.cancel')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                isLoading={isRestoreOrganizationLoading}
                disabled={isRestoreOrganizationDisabled}
                type="submit"
              >
                {t('common.restore')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(RestoreOrganizationModal);
